<template>
  <section class="section__container container-fluid">
    <div class="section__container_background">
      <v-img
        :cover="true"
        max-width="100vw"
        max-height="100%"
        src="/backgrounds/pipam_background.png"
        alt=""
      />
    </div>

    <nav
      style="background-image: linear-gradient(#F8F6EC, rgba(248, 246, 236, 0))"
      class="position--relative container-fluid about__page_nav d-flex justify-center align-center col-12"
    >
      <v-row
        class="container d-flex justify-center align-center pt-3 col-12 col-md-8"
      >
        <v-col class="about__page_nav-link">
          <router-link :to="{ name: 'cards' }">
            Cáritas
          </router-link>
        </v-col>
        <v-col class="about__page_nav-link">
          <router-link :to="{ name: 'sobre_pipam' }">
            PIPAM
          </router-link>
        </v-col>
        <v-col class="about__page_nav-link">
          <router-link :to="{ name: 'sobre_assessoria' }">
            Assessoria Técnica
          </router-link>
        </v-col>
        <v-col class="about__page_nav-link">
          <router-link :to="{ name: 'sobre_cadastro' }">
            O Cadastro
          </router-link>
        </v-col>
      </v-row>
    </nav>
    <div class="container position--relative">
      <v-row class="d-flex justify-end">
        <v-col sm="12" md="7">
          <Card
            title="Projeto de Incidência na Pauta da Mineração - PIPAM"
            borderColor="#C92F05"
            titleColor="#C92F05"
            backgroundColor="#F9F7ED"
            class="principal__card"
            title_alignment="center"
          >
            <template v-slot:conteudo>
              <p>
                O Projeto de Incidência na Pauta da Mineração (PIPAM), conduzido
                pela Cáritas Brasileira Regional Minas Gerais, com o apoio da
                Fundação Ford, busca incidir nacionalmente e internacionalmente
                para promover mudanças no setor minerário e garantir reparação
                integral às pessoas atingidas e à nossa Casa Comum. Surgido no
                contexto do rompimento da barragem de Fundão, que atingiu
                brutalmente toda a Bacia do Rio Doce, o escopo do projeto passou
                a abarcar ações de articulação, formação, incidência e
                comunicação estratégica também no quadro do processo de
                reparação de danos em razão de novo crime da mineração, desta
                vez na Bacia do Paraopeba, e do terror de barragens que ameaçam
                tantas outras comunidades e territórios em Minas Gerais.
              </p>
              <p>
                Inserido na área de atuação da Rede Cáritas denominada Meio
                Ambiente, Gestão de Riscos e Emergências, o projeto considera
                ainda o atual contexto de emergência climática, de apropriação
                privada dos recursos naturais, de impunidade corporativa e de
                enfraquecimento das instituições de proteção ambiental no
                Brasil.
              </p>
              <p>
                Dessa forma, busca o fortalecimento de grupos de atingidos,
                coletivos, movimentos sociais e organizações atuantes no
                enfrentamento à mineração, a partir de processos formativos, de
                articulação e de apoio na comunicação estratégica. Sendo assim,
                tem como objetivo aprimorar a comunicação com as comunidades
                atingidas e com organizações parceiras, bem como sensibilizar a
                opinião pública e incrementar a visibilidade do caso nos planos
                nacional e internacional, por meio das narrativas e construções
                comunitárias.
              </p>
            </template>
            <template v-slot:footerImage>
              <div class="card__footer_img mt-3 d-md-none">
                <img
                  width="50%"
                  src="/logos/logo-caritas-internationalis.png"
                  alt=""
                />
              </div>
            </template>
          </Card>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";

export default {
  components: {
    Card,
  },

  data() {
    return {};
  },
};
</script>

<style scoped>
a {
  color: #c92f05;
}
.router-link-exact-active {
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}

.router-link {
  color: #c92f05;
}

.section__container_background {
  position: fixed;
  top: 0;
}

@media (max-width: 600px) {
  .section__container_background {
    position: relative;
    top: 0;
  }
}

.position--relative {
  position: relative;
}
.section__container {
  position: relative;
  height: 100%;
}

.principal__card {
  max-width: 35em;
}

.card__footer_img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card__footer_img img {
  width: 40%;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}
</style>
